import { getRouterConfig } from 'app/ui/router'
import { always, equals, map, prop, propEq, when } from 'ramda'
import { createRoot } from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import './app/i18n'
import App from './app/ui'
// eslint-disable-next-line import/no-internal-modules
import 'public/globals.css'
import { activateSentry } from './app/sentry'

activateSentry()

const routerConfig = getRouterConfig()
const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: map(
      (route) => ({
        index: propEq('/', 'path', route),
        path: when(equals('/'), always(undefined), prop('path', route)),
        element: prop('element', route),
      }),
      routerConfig
    ),
  },
])

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container, {})
root.render(<RouterProvider router={router} />)
